// extracted by mini-css-extract-plugin
export var footer = "pd";
export var footerBottom = "wd";
export var footerBottom__copyWrapper = "Ad";
export var footerBottom__footerLink = "zd";
export var footerBottom__footerSocial = "Cd";
export var footerBottom__left = "xd";
export var footerBottom__links = "yd";
export var footerBottom__textDeveloper = "Bd";
export var footerTop = "rd";
export var footerTop__appButton = "ud";
export var footerTop__logo = "vd";
export var footerTop__menu = "td";
export var footerWrapper = "qd";
export var withButton = "sd";